.container svg {
	position: absolute;
	width: 400px;
	height: 400px;
	bottom: 0px;
	left: 0px;
	z-index: 1;
}

.container {
	background: black;
	text-align: center;
	height: 100vh;
	overflow: hidden;
}

video {
	height: 100%;
	z-index: 0;
	width: 177.77777778vh; /* 100 * 16 / 9 */
	min-width: 100%;
	min-height: 56.25vw; /* 100 * 9 / 16 */
}

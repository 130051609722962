#bg {
	color: #bbb;
	display: flex;
	flex-direction: column;
	padding: 64px;
	gap: 32px;
	overflow: hidden;
}
#tidelayer {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
}
#bg .link {
	text-align: left;
	position: absolute;
	bottom: 64px;
	left: 104px;
}
#tidelayer svg {
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
	font-family: "Open Sans", sans-serif;
}
.tick {
	font-size: 14px;
}
#root {
	overflow: hidden;
	display: flex;
	min-height: 100%;
}
#nowAxis path {
	stroke: inherit;
	stroke-width: 2;
}
#nowAxis g line {
	stroke: inherit;
}
#nowAxis g text {
	stroke: none;
}
#yAxis path {
	stroke-width: 2;
}
#xAxis path {
	stroke-width: 2;
}

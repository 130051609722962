@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700;800;&display=swap");
html,
body {
	font-family: "Open Sans", sans-serif;
	background-color: #222;
}

.App {
	text-align: center;
	overflow-wrap: break-word;
	color: white;
	min-height: 100vh;
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;
	font-size: 186px;
	font-weight: 800;
}

.App div {
	width: 100%;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.link {
	color: rgba(255, 255, 255, 0.9);
	font-size: 48px;
	font-weight: 600;
	letter-spacing: 12px;
}
